<template>
  <section class="boardWrap">
    <navi-bar>
      {{$t('front.common.qna')}}
    </navi-bar>

    <div class="msgreadwrap">
      <div class="servicewritehead">
        <select v-model="model.type">
          <template v-for="type in typeList" v-bind:key="type">
            <option :value="type">{{$t('front.qnaTypeCategory.' + type)}}</option>
          </template>
        </select>
      </div>
      <div class="servicewriteinput">
        <input type="text" :placeholder="$t('front.search.title')" v-model="model.title"/>
        <textarea :placeholder="$t('front.board.commentPlaceholder')" v-model="model.content"></textarea>
      </div>

    </div>

    <div class="sbtnwrap">
      <a class="ok btncancel" @click="onClickCancel">{{$t('front.button.cancel')}}</a>
      <a class="all" @click="onClickSubmit">{{$t('front.board.reg')}}</a>
    </div>
  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import QnaWrite from '@/views/member/cscenter/qna/write'

export default {
  name: 'QnaWriteMobile',
  components: {
    NaviBar
  },
  mixins: [QnaWrite]
}
</script>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
